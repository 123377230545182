import CustomCursor from './Utils/CustomCursor.js'
import PhotographyExperience from './Experience/PhotographyExperience.js'
// import ShaderMondayExperience from './Experience/ShaderMondayExperience.js'
import CustomScroll from './Utils/CustomScroll.js'
import Sizes from './Utils/Sizes.js'
import MatchMedia from './Utils/MatchMedia.js'
import Menu from './Menu/Menu.js'



let instance = null

export default class Images
{
    constructor()
    {
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this

        // Setup
        this.CustomCursor = new CustomCursor()
        this.sizes = new Sizes()
        this.scroll = new CustomScroll()
        this.media = new MatchMedia()

        this.experience = new PhotographyExperience({domElement: document.getElementById('container')})


        this.sizes.on('resize', () =>
        {
            this.resize()
        })

    }

    resize() {
        this.scroll.resize()
        this.experience.resize()
    }
}

