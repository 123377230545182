import EventEmitter from './EventEmitter.js'
import Main from '../Main.js'

export default class Positions extends EventEmitter 
{
    constructor() 
    {
        super()

        // Options
        this.main = new Main()
        this.sizes = this.main.sizes


        // Setup Scrollposition
        this.scrollY = window.scrollY
        this.scrollX = window.scrollX

        // Setup Cursorposition
        this.cursor = {}
        this.cursor.x = 0
        this.cursor.y = 0

        // Scroll event
        window.addEventListener('scroll', () => { 
            this.scrollY = window.scrollY
            this.scrollX = window.scrollX
        })

        // Cursormove event
        window.addEventListener('mousemove', (event) => { 
            this.cursor.x = event.clientX / this.sizes.width - 0.5
            this.cursor.y = event.clientY / this.sizes.height - 0.5
        })


    }
}
