import gsap from "gsap";
import CustomScroll from "./Utils/CustomScroll.js";

import CustomCursor from "./Utils/CustomCursor.js";
import Sizes from "./Utils/Sizes.js";
import Positions from "./Utils/Positions.js";
import Menu from "./Menu/Menu.js";
import Works from "./Animations/WorksAnimation.js";
import Contact from "./Animations/ContactAnimation.js";
import About from "./Animations/AboutAnimation.js";

// import barba from '@barba/core'
let instance = null;

  // const preloader = document.querySelector('.preloader-main');

export default class Main {
  constructor() {

    // Singleton
    if (instance) {
      return instance;
    }
    instance = this;

    //Setup

    this.sizes = new Sizes();
    this.positions = new Positions();
    this.menu = new Menu();
    this.works = new Works();
    this.about = new About();
    this.contact = new Contact();
    
    // Options
    this.worksWrapper = document.querySelectorAll(".works-wrapper");

    // Functions on pageload
    this.customCursor = new CustomCursor();
    this.customCursor.home()

    this.init();
    this.works.eventListeners();

    // Actions on resize event
    this.sizes.on("resize", () => {
      this.resize();
    });
  }

  // preloader() { 
  //   preloader.parentElement.removeChild(preloader);
  // }

  jsAnchorTagDestination() {
    window.location.href = "destination.html";
  }

  jsAnchorTagNarrative() {
    window.location.href = "narrative.html";
  }

  jsAnchorTagShader() {
    window.location.href = "shader.html";
  }

  jsAnchorTagMiniMenu() {
    window.location.href = "index.html";
  }

  resize() {
    this.sizes.handleResize();
    this.works.eventListeners();
    // this.mainExperience.resize();
  }

  // starting condition on pageload
  init() {
    this.worksWrapper[0].addEventListener(
      "click",
      this.jsAnchorTagNarrative.bind(this)
    );
    this.worksWrapper[1].addEventListener(
      "click",
      this.jsAnchorTagDestination.bind(this)
    );
    this.worksWrapper[2].addEventListener(
      "click",
      this.jsAnchorTagShader.bind(this)
    );

    this.works.Marquee(".narrative-box", "+=1225", 1225, 175);
    this.works.Marquee(".destination-box", "+=1463", 1463, 209);
    this.works.Marquee(".portrait-box", "+=1200", 1200, 150);

    if (this.sizes.desktop.matches) {
      this.worksWrapper[0].setAttribute("style", "width: 33%");
      this.worksWrapper[1].setAttribute("style", "width: 33%");
      this.worksWrapper[2].setAttribute("style", "width: 33%");

      this.menu.initializeMenu(110);
      this.about.aboutIntroAnimation().play();
    } else if (this.sizes.tablet.matches) {
      this.worksWrapper[0].setAttribute("style", "width: 100%");
      this.worksWrapper[1].setAttribute("style", "width: 100%");
      this.worksWrapper[2].setAttribute("style", "width: 100%");

      this.menu.initializeMenu(-110);
      this.about.aboutIntroAnimation().play();
    } else {
      this.worksWrapper[0].setAttribute("style", "width: 100%");
      this.worksWrapper[1].setAttribute("style", "width: 100%");
      this.worksWrapper[2].setAttribute("style", "width: 100%");

      this.menu.initializeMenuMobile(-101);
      this.about.aboutIntroAnimation().play();
    }
  }

}
