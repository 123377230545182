import * as THREE from "three";
import gsap from "gsap";

import Images from "../Images.js";
import CustomCursor from "../Utils/CustomCursor";

// shaders
import vertex from "./shaders/vertex.glsl";
import vertexVanGogh from "./shaders/vertexVanGogh.glsl";
import vertexWater from "./shaders/vertexWater.glsl";
import fragment from "./shaders/fragment.glsl";
import fragmentPassMobile from "./shaders/fragmentPassMobile.glsl";
import fragmentPassDesktop from "./shaders/fragmentPassDesktop.glsl";

// shader monday import
import fragmentG1 from "./shaders/fragment-G1.glsl";
import fragmentG2 from "./shaders/fragment-G2.glsl";
import fragmentG3 from "./shaders/fragment-G3.glsl";
import fragmentG4 from "./shaders/fragment-G4.glsl";
import fragmentG5 from "./shaders/fragment-G5.glsl";
import fragmentG6 from "./shaders/fragment-G6.glsl";
import fragmentG7 from "./shaders/fragment-G7.glsl";
import fragmentG8 from "./shaders/fragment-G8.glsl";
import fragmentG9 from "./shaders/fragment-G9.glsl";
import fragmentG10 from "./shaders/fragment-G10.glsl";
import fragmentG11 from "./shaders/fragment-G11.glsl";
import fragmentG12 from "./shaders/fragment-G12.glsl";
import fragmentG13 from "./shaders/fragment-G13.glsl";
import fragmentG14 from "./shaders/fragment-G14.glsl";
import fragmentG15 from "./shaders/fragment-G15.glsl";
import fragmentG16 from "./shaders/fragment-G16.glsl";
import fragmentG17 from "./shaders/fragment-G17.glsl";
import fragmentG18 from "./shaders/fragment-G18.glsl";
import fragmentG19 from "./shaders/fragment-G19.glsl";
import fragmentG20 from "./shaders/fragment-G20.glsl";
import chroma1 from"./shaders/chroma1.glsl";
import chroma2 from"./shaders/chroma2.glsl";
import chroma3 from"./shaders/chroma3.glsl";
import chroma5 from"./shaders/chroma5.glsl";
import chroma6 from"./shaders/chroma6.glsl";
import chroma8 from"./shaders/chroma8.glsl";
import fragmentG21 from "./shaders/fragment-G21.glsl";
import fragmentG22 from "./shaders/fragment-G22.glsl";
import fragmentG23 from "./shaders/fragment-G23.glsl";
import fragmentG24 from "./shaders/fragment-G24.glsl";
import fragmentG25 from "./shaders/fragment-G25.glsl";
import fragmentG26 from "./shaders/fragment-G26.glsl";
import fragmentG27 from "./shaders/fragment-G27.glsl";
import fragmentG28 from "./shaders/fragment-G28.glsl";
import fragmentG29 from "./shaders/fragment-G29.glsl";
import fragmentG30 from "./shaders/fragment-G30.glsl";
import fragmentG31 from "./shaders/fragment-G31.glsl";
import fragmentG32 from "./shaders/fragment-G32.glsl";
import fragmentG33 from "./shaders/fragment-G33.glsl";
import fragmentG34 from "./shaders/fragment-G34.glsl";


// textures
import texture from "../../../static/images/dummyTexture.jpg";

// shader monday import
// import beforeAfter1 from "../../../static/images/shadesOfShaders/beforeAfter1.jpg"
import beforeAfter2 from "../../../static/images/shadesOfShaders/beforeAfter2.jpg"
import depthMap from "../../../static/images/shadesOfShaders/depth-map.jpg"
import displacementG7 from "../../../static/images/shadesOfShaders/displace.jpg"
import starryNight from "../../../static/images/shadesOfShaders/starryNight.jpg"
import afterShot from "../../../static/images/destination-main.jpg"
// import displacementG7 from "../../../static/images/shadesOfShaders/textureG7.jpg"
// import barrelDistortion from "../../../static/images/shadesOfShaders/barrelDistortionWB.jpg"
// import shader03 from "../../../static/images/shadesOfShaders/shader03.jpg"

// postprocessing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";



export default class Sketch {
  constructor(options) {

    //Setup
    this.images = new Images();
    this.scroll = this.images.scroll;
    this.loadingManager = new THREE.LoadingManager()
    this.textureLoader = new THREE.TextureLoader(this.loadingManager)

    //shader0 second texture
    if (document.querySelector("body").classList.contains("shader-bg")) {
      // this.texture2 = this.textureLoader.load(beforeAfter2)
    }

    this.loadingManager.onLoad = () => {
      // this.preloader.parentElement.removeChild(this.preloader);
      }
    
    // Options
    this.desktop = this.images.media.desktop.matches;
    this.tablet = this.images.media.tablet.matches;
    this.mobile = this.images.media.mobile.matches;

    // Create Material array
    this.materials = [];
    // ImageStore
    this.imageStore = [];
    // ShaderStore
    this.shaderStore = [fragmentG2, fragmentG1, fragmentG3, fragmentG4, fragmentG5, fragmentG6, fragmentG7, fragmentG8, fragmentG9, fragmentG10, fragmentG11, fragmentG12, fragmentG13, fragmentG14, fragmentG15, fragmentG16, fragmentG17, fragmentG18, fragmentG19, fragmentG20, chroma1, chroma2, chroma3, chroma5, chroma6, chroma8, fragmentG21, fragmentG22, fragmentG23, fragmentG24, fragmentG25, fragmentG26, fragmentG27, fragmentG28, fragmentG29, fragmentG30, fragmentG31, fragmentG32, fragmentG33, fragmentG34];
    
    // CustomShader
    this.myEffect = {};

    this.headline = document.querySelector(".destination-headline")
    this.headlineContainer = document.querySelector(".destination-headline-container")
    this.fadeController = 1;
    // Renderer container
    this.container = options.domElement;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;

  
    // this.camera = new THREE.PerspectiveCamera( 70, this.width / this.height, 0.01, 10000)
    // this.camera.position.z = 600;
    // this.camera.fov = 2 * ((Math.atan(this.height / 2 / 600) * 180) / Math.PI);
    // this.scene = new THREE.Scene();
    // this.renderer = new THREE.WebGLRenderer({
    //   antialias: true,
    //   alpha: true,
    // });
    // this.renderer.setPixelRatio((Math.min(window.devicePixelRatio, 2)));
    // this.container.appendChild(this.renderer.domElement);

    // /**
    //  * Raycaster
    //  */
    // this.raycaster = new THREE.Raycaster();

    /**
     * Mouse
     */
    this.mouse = new THREE.Vector2();

    this.time = 0;

    // Methods Call
    this.setupThree()
    this.setupSettings();
    this.closerHover();
    this.headlineColor = this.initHeadlineColor();
    this.addObjects();
    this.mouseMovement();
    this.resize();
    this.corner();
    this.composerPass();
    this.render();
  }

  setupThree() {

    this.camera = new THREE.PerspectiveCamera( 70, this.width / this.height, 0.01, 10000)
    this.camera.position.z = 600;
    this.camera.fov = 2 * ((Math.atan(this.height / 2 / 600) * 180) / Math.PI);
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    this.renderer.setPixelRatio((Math.min(window.devicePixelRatio, 2)));
    this.container.appendChild(this.renderer.domElement);

    // initialize Raycaster
    this.raycaster = new THREE.Raycaster();

  }

  initHeadlineColor() {
    const headlineColor = gsap.timeline({})
      .set(".portfolio-color-bg", {
        xPercent: 0,
        left: "100%",
        yPercent: 0,
        top: "100%" })
      .to(".portfolio-color-bg", {
        xPercent: -100,
        yPercent: -100,
        duration: 1 })
      .to( ".portfolio-color-bg",
        { rotation: "+=360", repeat: -1, duration: 6, ease: "none"  },"<")
      .to(".portfolio-color-bg", { x: -50, yoyo: true }, "<")
      .to(".portfolio-color-bg", { scale: 1.3, yoyo: true }, "<");
      return headlineColor
  }

  animateHeadline() {
    if (
      ((this.scroll.scroll.scroll.instance.scroll.x >= 150 &&
        this.width >= 950) ||
        (this.scroll.scroll.scroll.instance.scroll.y >= 150 &&
          this.width <= 950)) &&
      this.fadeController == 1
    ) {
      this.fadeOutHeadline();
      this.fadeController = 0;
      return;
    } else if (
      ((this.scroll.scroll.scroll.instance.scroll.x <= 150 &&
        this.width >= 950) ||
        (this.scroll.scroll.scroll.instance.scroll.y <= 150 &&
          this.width <= 950)) &&
      this.fadeController == 0
    ) {
      this.fadeInHeadline();
      this.fadeController = 1;
      return;
    }
  }

  fadeInHeadline() {
    const animation = gsap.timeline({});
    animation
      .to(this.headlineContainer, { opacity: 1, duration: 2 })
      .to(".portfolio-color-bg", { opacity: 1, duration: 0.5 }, "<");
      this.headlineColor.play()
    return animation;
  }

  fadeOutHeadline() {
    const animation = gsap.timeline({});
    animation
      .to(this.headlineContainer, { opacity: 0, duration: 2 })
      .to(".portfolio-color-bg", { opacity: 0, duration: 0.5 }, "<")
      this.headlineColor.pause()
    return animation;
  }

  closerHover() {
    document.querySelector(".closer-headline-1").addEventListener("mouseover", () => {
        if (this.desktop || this.tablet) {
          const animation = gsap.timeline({});
          animation
            .to(".outro-mask-1", { yPercent: -100, duration: 0.2 }, "<")
            .to(".outro-image", { scale: 0.9, duration: 5.5 }, "<");
          return animation;
        }
      });
    document.querySelector(".closer-headline-1").addEventListener("mouseout", () => {
        if (this.desktop || this.tablet) {
          const animation = gsap.timeline({});
          animation
            .to(".outro-mask-1", { yPercent: 0, duration: 0.2 }, "<")
            .to(".outro-image", { scale: 1.0, duration: 1.5 }, "<");
          return animation;
        }
      });

    document.querySelector(".closer-headline-2").addEventListener("mouseover", () => {
        if (this.desktop || this.tablet) {
          const animation = gsap.timeline({});
          animation
            .to(".outro-mask-2", { yPercent: 100, duration: 0.3 }, "<")
            .to(".outro-image", { scale: 0.9, duration: 5.5 }, "<");
          return animation;
        }
      })
    document.querySelector(".closer-headline-2").addEventListener("mouseout", () => {
        if (this.desktop || this.tablet) {
          const animation = gsap.timeline({});
          animation
            .to(".outro-mask-2", { yPercent: 0, duration: 0.3 }, "<")
            .to(".outro-image", { scale: 1.0, duration: 1.5 }, "<");
          return animation;}
      });
  }



  // // Raycaster on mousemove
  mouseMovement() {
    window.addEventListener(
      "mousemove",
      (event) => {
        //fullscreen mousemove, no canvas offset required
        this.mouse.x = (event.clientX / this.width) * 2 - 1;
        this.mouse.y = -(event.clientY / this.height) * 2 + 1;

        // update the picking ray with the camera and pointer position
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects(this.scene.children);
        if (intersects.length > 0) {
          let obj = intersects[0].object;
          obj.material.uniforms.uHover.value = intersects[0].uv;
        }
      },
      false
    );
  }

  composerPass() {
    this.composer = new EffectComposer(this.renderer);
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);

    //custom shader pass
    var counter = 0.0;
    this.myEffect = {
      uniforms: {
        tDiffuse: { value: null },
      },
      vertexShader: `
          varying vec2 vUv;
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix 
              * modelViewMatrix 
              * vec4( position, 1.0 );
          }
          `,
      fragmentShader: this.mobile ? fragmentPassMobile : fragmentPassDesktop,
    };

    this.customPass = new ShaderPass(this.myEffect);
    this.customPass.renderToScreen = true;
    // this.customPass.enabled = false
    this.composer.addPass(this.customPass);
  }

  corner() {
    this.tl = gsap.timeline();
    this.tl.progress(this.settings.progress);
    this.tl
      .to(this.material.uniforms.uCorners.value, { x: 1 })
      .to(this.material.uniforms.uCorners.value, { y: 1 }, "-=0.3")
      .to(this.material.uniforms.uCorners.value, { z: 1 }, "-=0.4")
      .to(this.material.uniforms.uCorners.value, { w: 1 }, "-=0.3");
    return this.tl;
  }

  setupSettings() {
    this.settings = {
      progress: 0,
    };
  }

  resize() {
    // this.animateHeadline();
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // update EffectComposer
    if (this.composer) {
      this.composer.setSize(this.width, this.height);
      this.composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      this.customPass.enabled = false;
      this.myEffect.fragmentShader =
        this.width <= 950 ? fragmentPassMobile : fragmentPassDesktop;
      this.customPass = new ShaderPass(this.myEffect);
      this.customPass.renderToScreen = true;
      this.composer.addPass(this.customPass);
    }

    // update camera
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.camera.fov = (2 * Math.atan(this.height / 2 / 600) * 180) / Math.PI;

    // update shared uniforms
    this.materials.forEach((material) => {
      material.uniforms.uResolution.value.x = this.width;
      material.uniforms.uResolution.value.y = this.height;
    });

    // update image positions
    this.imageStore.forEach((item) => {
      let bounds = item.img.getBoundingClientRect();
      item.mesh.scale.set(bounds.width, bounds.height, 1);
      item.top = bounds.top + this.scroll.scroll.scroll.instance.scroll.x;
      item.left = bounds.left + this.scroll.scroll.scroll.instance.scroll.x;
      item.width = bounds.width;
      item.height = bounds.height;
      item.mesh.material.uniforms.uQuadSize.value.x = bounds.width;
      item.mesh.material.uniforms.uQuadSize.value.y = bounds.height;
      item.mesh.material.uniforms.uTextureSize.value.x = bounds.width;
      item.mesh.material.uniforms.uTextureSize.value.y = bounds.height;
    });
  }

  addObjects() {
    let i = 0;
    this.geometry = new THREE.PlaneGeometry(1, 1, 10, 10);

    this.material = new THREE.ShaderMaterial({
      // wireframe: true,
      uniforms: {
        uTime: { value: 1.0 },
        uHover: { value: new THREE.Vector2(0., 0.5) },
        uHoverState: { value: 0 },
        uProgress: { value: 1.0 },
        uResolution: { value: new THREE.Vector2(this.width, this.height) },
        uMouse: {value: this.mouse},
        uTextureSize: { value: new THREE.Vector2(1, 1) },
        uQuadSize: { value: new THREE.Vector2(1, 1) },
        uTexture: { value: this.textureLoader.load(texture) },
        uTexture2: {value: this.textureLoader.load(texture) },
        uCorners: { value: new THREE.Vector4(0, 0, 0, 0) },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.scale.set(400, 500, 1);
    this.mesh.position.y = 100;

    // Get all images from destination content container and store them in array
      this.images = document.querySelectorAll(".destination-image");
      this.images = [...this.images];

    // Create Meshes for each image from images array and return an parameter object for each instancd
    this.imageStore = this.images.map((img) => {
      let bounds = img.getBoundingClientRect();

      let m = this.material.clone();

      // add individual shaders to shader garden page
      if (document.querySelector("body").classList.contains("shader-bg")) {
        m.fragmentShader = this.shaderStore[i];

        // add second texture to fragment
        if(i==1 ) {
          this.texture2 = this.textureLoader.load(beforeAfter2)
          m.uniforms.uTexture2.value = this.texture2;
        }

        if(i==5 ) {
          this.texture2 = this.textureLoader.load(depthMap)
          m.uniforms.uTexture2.value = this.texture2;
        }

        if(i==6 ) {
          this.texture2 = this.textureLoader.load(displacementG7)
          m.uniforms.uTexture2.value = this.texture2;
        }

        if(i==8 ) {
          this.texture2 = this.textureLoader.load(starryNight)
          m.uniforms.uTexture2.value = this.texture2;
          m.vertexShader = vertexVanGogh;
        }

        if(i==15 ) {
          this.texture2 = this.textureLoader.load(afterShot)
          m.uniforms.uTexture2.value = this.texture2;
          // m.vertexShader = vertexVanGogh;
        }

        if(i==35 ) {
          // this.texture2 = this.textureLoader.load(afterShot)
          // m.uniforms.uTexture2.value = this.texture2;
          m.vertexShader = vertexWater;
        }


        i++;
      }

      img.addEventListener("mouseenter", () => {
        gsap.to(m.uniforms.uHoverState, {
          duration: 1,
          value: 1,
        });
      });

      img.addEventListener("mouseout", () => {
        gsap.to(m.uniforms.uHoverState, {
          duration: 1,
          value: 0,
        });
      });

      this.materials.push(m);
      let mesh = new THREE.Mesh(this.geometry, m);

      // the following 2 lines are needed to avoid texture overflow bug in current version of three.js
      const image = new Image();
      image.src = img.src;

      let texture = new THREE.Texture(image);
      texture.needsUpdate = true;
      m.uniforms.uTexture.value = texture;
      m.uniforms.uTextureSize.value = new THREE.Vector2(bounds.width, bounds.height);
      m.uniforms.uQuadSize.value = new THREE.Vector2(bounds.width, bounds.height);
      mesh.scale.set(bounds.width, bounds.height, 1);
      this.scene.add(mesh);
      return {
        img: img,
        mesh: mesh,
        width: bounds.width,
        height: bounds.height,
        top: bounds.top,
        left: bounds.left,
      };
    });
  }

  setPosition() {
    this.imageStore.forEach((obj) => {
      obj.mesh.position.x =
        obj.left -
        this.width / 2 +
        obj.width / 2 -
        this.scroll.scroll.scroll.instance.scroll.x;
      obj.mesh.position.y =
        -obj.top +
        this.height / 2 -
        obj.height / 2 +
        this.scroll.scroll.scroll.instance.scroll.y;
    });
  }

  render() {
    this.animateHeadline();
    this.time += 0.05;
    this.material.uniforms.uMouse.value = this.mouse;
    this.material.uniforms.uTime.value = this.time;
    this.material.uniforms.uProgress.value = this.settings.progress;

    this.materials.forEach((material) => {
      material.uniforms.uTime.value = this.time;
    });

    this.setPosition();
    this.tl.progress(this.settings.progress);
    this.composer.render();

    requestAnimationFrame(this.render.bind(this));
  }
}
