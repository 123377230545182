import gsap from 'gsap'

export default class CustomCursor {
  constructor() {

    //Setup
    this.circle = document.getElementById("circle");
    this.circleStyle = this.circle.style;
    this.cursor = document.getElementById("cursor");
    this.cursorStyle = this.cursor.style;
    this.cursorStyle.opacity = 0;
    this.circle.style.opacity = 0;
    this.mouse = { x: -100, y: -100 };
    this.ShaderMouse = { x: -100, y: -100 };
    this.pos = { x: 0, y: 0 };
    this.speed = 0.1; // between 0 and 1

    this.common()

    window.addEventListener("mousemove", this.updateCoordinates.bind(this));

    requestAnimationFrame(this.loop.bind(this));

    document.addEventListener("mousemove", (e) => {
      this.updateCoordinates.bind(this)
      window.requestAnimationFrame(() => {
        this.circleStyle.top = `${e.clientY - this.circle.offsetHeight / 2}px`;
        this.circleStyle.left = `${e.clientX - this.circle.offsetWidth / 2}px`;
        this.cursorStyle.opacity = 1;
        this.circleStyle.opacity = 1;
      });
    });
  }

  updatePosition() {
      this.pos.x += (this.mouse.x - this.pos.x) * this.speed;
      this.pos.y += (this.mouse.y - this.pos.y) * this.speed;
      this.cursorStyle.transform =
        "translate3d(" + this.pos.x + "px ," + this.pos.y + "px, 0)";
    }

  updateCoordinates(e) {

      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      // this.ShaderMouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      // this.ShaderMouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
      this.ShaderMouse.x = -e.clientX;
      this.ShaderMouse.y = e.clientY;
    }

    loop() {
      this.updatePosition();
      requestAnimationFrame(this.loop.bind(this));
    }

    // JJ SPECIFIC CODE
    common() {
      let exploreTrigger = document.querySelectorAll(".cursor-explore");
      exploreTrigger = [...exploreTrigger];
      exploreTrigger.forEach((trigger) => {
        trigger.addEventListener("mouseover", () => {
          document
            .querySelector(".cursor--inner")
            .classList.add("cursor--active");
        });
        trigger.addEventListener("mouseout", () => {
          document
            .querySelector(".cursor--inner")
            .classList.remove("cursor--active");
        });
      });
  
      // mini menu hover state
      document
        .querySelector(".menu-icon-circle")
        .addEventListener("mouseover", () => {
          gsap.to('.menu-icon-circle', {scale: 1.2})
          document
            .querySelector(".cursor--inner")
            .classList.add("cursor--active-menu");
        });
  
      document
        .querySelector(".menu-icon-circle")
        .addEventListener("mouseout", () => {
          gsap.to('.menu-icon-circle', {scale: 1.})
          document
            .querySelector(".cursor--inner")
            .classList.remove("cursor--active-menu");
        });

        document
        .querySelector(".menu-icon-circle")
        .addEventListener("click", () => {
          gsap.to('.menu-icon-circle', {scale: 1.})
          document
            .querySelector(".cursor--inner")
            .classList.remove("cursor--active-menu");
        });

      }

        home() {
        document
        .querySelector(".contact-button")
        .addEventListener("mouseover", () => {
  gsap.to('.contact-button', {scale: 0.8})
          document
            .querySelector(".cursor--inner")
            .classList.add("cursor--active-contact");
        });
  
      document
        .querySelector(".contact-button")
        .addEventListener("mouseout", () => {
          gsap.to('.contact-button', {scale: 1.})
          document
            .querySelector(".cursor--inner")
            .classList.remove("cursor--active-contact");
        });

        // social buttons
        document
        .querySelector(".twitter-link")
        .addEventListener("mouseover", () => {

          document
            .querySelector(".cursor--inner")
            .classList.add("cursor--active-twitter");
        });
  
      document
        .querySelector(".twitter-link")
        .addEventListener("mouseout", () => {

          document
            .querySelector(".cursor--inner")
            .classList.remove("cursor--active-twitter");
        });

        document
        .querySelector(".instagram-link")
        .addEventListener("mouseover", () => {

          document
            .querySelector(".cursor--inner")
            .classList.add("cursor--active-instagram");
        });
  
      document
        .querySelector(".instagram-link")
        .addEventListener("mouseout", () => {

          document
            .querySelector(".cursor--inner")
            .classList.remove("cursor--active-instagram");
        });
        }
    }
  

