import gsap from "gsap";
import EventEmitter from "../Utils/EventEmitter.js";
import MenuAnimation from '../Animations/MenuAnimation.js'
import Works from "../Animations/WorksAnimation.js";
import Contact from "../Animations/ContactAnimation.js";
import About from "../Animations/AboutAnimation.js";
import Main from "../Main.js";

let instance = null;

export default class Menu extends EventEmitter {
  constructor() {

    super();
        // Singleton
        if (instance) {
          return instance;
        }
        instance = this;
    
    // Setup
    this.menu = new Main();
    this.sizes = this.menu.sizes;
    this.menuItem = document.querySelectorAll(".menu-font");
    this.menuIcon = document.querySelector(".menu-icon-circle");
    this.menuIconItem = document.querySelectorAll(".menu-font-icon");
    this.sideColor = document.querySelector(".side-color");
    this.works = new Works();
    this.about = new About();
    this.contact = new Contact();
    this.menuAnimation = new MenuAnimation();

    this.init();
    this.setMenu();
    this.setMenuIcon();
  }

  jsAnchorTagMenu() {
    window.location.href = "index.html";
  }

  setMenu() {
    if (this.sizes.desktop.matches) {
      gsap.set(".menu-about", { left: null, top: null, right: 0, bottom: 0 });
      gsap.set(".menu-works", { left: this.menuItem[0].offsetWidth, top: null, right: null, bottom: 0 });
      gsap.set(".menu-contact", { left: 0, top: null, right: null, bottom: 0 });
    } else if (this.sizes.tablet.matches) {
      gsap.set(".menu-about", { top: 0, left: null, right: 0, bottom: null });
      gsap.set(".menu-works", {top: 0, left: this.menuItem[0].offsetWidth, right: null, bottom: null });
      gsap.set(".menu-contact", { top: 0, left: 0, right: null, bottom: null });
    } else {
      gsap.set(".menu-about", { left: 0, top: null, right: null, bottom: 0 });
      gsap.set(".menu-works", { left: 0, top: this.menuItem[0].offsetHeight, right: null, bottom: null });
      gsap.set(".menu-contact", { left: 0, top: 0, right: null, bottom: null });
    }
  }

  setFontOpacity1(target) {
    gsap.to(target, {opacity: 1., duration: 0.3})
  }

  setFontOpacity0(target) {
    gsap.to(target, {opacity: 0.3, duration: 0.3})
  }

  setMenuIcon() {
    if (this.sizes.desktop.matches) {
      gsap.set(".menu-about-icon", { left: null, top: null, right: 0, bottom: 0 });
      gsap.set(".menu-works-icon", { left: this.menuIconItem[0].offsetWidth, top: null, right: null, bottom: 0 });
      gsap.set(".menu-contact-icon", { left: 0, top: null, right: null, bottom: 0 });
    } else if (this.sizes.tablet.matches) {
      gsap.set(".menu-about-icon", { top: 0, left: null, right: 0, bottom: null });
      gsap.set(".menu-works-icon", {top: 0, left: this.menuIconItem[0].offsetWidth, right: null, bottom: null });
      gsap.set(".menu-contact-icon", { top: 0, left: 0, right: null, bottom: null });
    } else {
      gsap.set(".menu-about-icon", { left: 0, top: null, right: null, bottom: 0 });
      gsap.set(".menu-works-icon", { left: 0, top: this.menuIconItem[0].offsetHeight, right: null, bottom: null });
      gsap.set(".menu-contact-icon", { left: 0, top: 0, right: null, bottom: null });
    }
}

  initializeMenu(startPos) {
    const animation = gsap
      .timeline({ defaults: { duration: 1.2 } })
      .fromTo(
        ".menu-about",
        { right: 0, yPercent: startPos },
        { yPercent: 0, duration: 1.2 }
      )
      .from(".side-color", { right: -this.sideColor.offsetWidth }, "<")
      .fromTo( ".menu-works", { left: 0, yPercent: startPos, left: this.menuItem[0].offsetWidth }, { yPercent: 0, left: this.menuItem[0].offsetWidth }, 0.4
      )
      .fromTo( ".menu-contact", { left: 0, yPercent: startPos }, { yPercent: 0 },
        "-=.8"
      )
    return animation;
  }

  initializeMenuMobile(startPos) {
    const animation = gsap
      .timeline({ defaults: { duration: 1.2 } })
      .set('.menu-about', {opacity: 0})
      .fromTo(
        ".menu-about",
        { xPercent: startPos },
        { xPercent: 0, duration: 1.2 }
      )
      .fromTo(".menu-works", { xPercent: startPos }, { xPercent: 0 }, 0.4)
      .fromTo(".menu-contact", { xPercent: startPos }, { xPercent: 0 }, "-=.8")

      const animationIcon = gsap
      .timeline({ defaults: { duration: 1.2 } })
      .fromTo(
        ".menu-about-icon",
        { opacity: 0, xPercent: startPos },
        { opacity: 1, xPercent: 0, duration: 1.2 }
      )
      .fromTo(".menu-works-icon", { opacity: 0, xPercent: startPos }, { opacity: 1, xPercent: 0 }, 0.4)
      .fromTo(".menu-contact-icon", { opacity: 0,xPercent: startPos }, { opacity: 1, xPercent: 0 }, "-=.8")
    return animation;
  }

  init() {
  // Menu click event
    this.menuItem.forEach((menu) => {
      menu.addEventListener("click", this.handleClick.bind(this));
    })

  // Menu mouseover event
    this.menuItem.forEach((menu) => {
      menu.addEventListener("mouseover", this.handleMouseOver.bind(this));
    })
  
    // Menu mouseout event
    this.menuItem.forEach((menu) => {
      menu.addEventListener("mouseout", this.handleMouseOut.bind(this));
    })
  }

  handleMouseOverImproved() {
    if(document.querySelector(primary).matches(':hover')) {
      document.querySelector(".cursor--inner").classList.add("cursor--active");
      this.setFontOpacity0(document.querySelector(primary));
    } 
    if (document.querySelector(secondary).matches(':hover')) {
      document.querySelector(".cursor--inner").classList.add("cursor--active");
      this.setFontOpacity0(document.querySelector(secondary));
    }
  }

  handleMouseOver(event) {
    if(window.innerWidth > 550) {
    if((!event.target.classList.contains('is-active') && !this.menuAnimation.animationIsActive) ) {
      document.querySelector(".cursor--inner").classList.add("cursor--active");
    }

    if((!event.target.classList.contains('is-active') && !this.menuAnimation.animationIsActive) ) {
      let animation = gsap.to(event.target, {opacity: 1.0, duration: 0.3})
      return animation
    }
  }
  }

  handleMouseOut(event) {
    if(window.innerWidth > 550) {
      // if((!event.target.classList.contains('is-active') && !this.menuAnimation.animationIsActive) ) {
        document.querySelector(".cursor--inner").classList.remove("cursor--active");
      // }

      // if((!event.target.classList.contains('is-active') && !this.menuAnimation.animationIsActive) ) {
        let animation = gsap.to(event.target, {opacity: 0.5, duration: 0.3})
        return animation
      // }
    }
  }

  handleClick(event) {
    if (
      !this.menuAnimation.animationIsActive &&
      (this.sizes.desktop.matches || this.sizes.tablet.matches)
    ) {
      // animate works
      document.querySelector(".cursor--inner").classList.remove("cursor--active");
      event.target.classList.contains("menu-works") &&
      document.querySelector(".menu-about").classList.contains("is-active")
        ? (this.menuAnimation.primaryAnimation(
            ".menu-about",
            ".menu-works",
            ".menu-contact"
          ),
          this.menuAnimation.primaryIconAnimation(
            ".menu-about-icon",
            ".menu-works-icon",
            ".menu-contact-icon"
          ),
          this.works.primaryAnimation().restart().play())
        : event.target.classList.contains("menu-works") &&
          document
            .querySelector(".menu-contact")
            .classList.contains("is-active")
        ? (this.menuAnimation.secondaryAnimation(
            ".menu-contact",
            ".menu-about",
            ".menu-works"
          ),
          this.menuAnimation.secondaryIconAnimation(
            ".menu-contact-icon",
            ".menu-about-icon",
            ".menu-works-icon"
          ),
          this.works.secondaryAnimation().restart().play())
        : null;

      // animate contact
      document.querySelector(".cursor--inner").classList.remove("cursor--active");
      event.target.classList.contains("menu-contact") &&
      document.querySelector(".menu-works").classList.contains("is-active")
        ? (this.menuAnimation.primaryAnimation(
            ".menu-works",
            ".menu-contact",
            ".menu-about"
          ),
          this.menuAnimation.primaryIconAnimation(
            ".menu-works-icon",
            ".menu-contact-icon",
            ".menu-about-icon"
          ),
          this.contact.primaryAnimation().restart().play())
        : event.target.classList.contains("menu-contact") &&
          document.querySelector(".menu-about").classList.contains("is-active")
        ? (this.menuAnimation.secondaryAnimation(
            ".menu-about",
            ".menu-works",
            ".menu-contact"
          ),
          this.menuAnimation.secondaryIconAnimation(
            ".menu-about-icon",
            ".menu-works-icon",
            ".menu-contact-icon"
          ),
          this.contact.secondaryAnimation().restart().play())
        : null;

      // animate about
      document.querySelector(".cursor--inner").classList.remove("cursor--active");
      event.target.classList.contains("menu-about") &&
      document.querySelector(".menu-contact").classList.contains("is-active")
        ? (this.menuAnimation.primaryAnimation(
            ".menu-contact",
            ".menu-about",
            ".menu-works"
          ),
          this.menuAnimation.primaryIconAnimation(
            ".menu-contact-icon",
            ".menu-about-icon",
            ".menu-works-icon"
          ),
          this.about.primaryAnimation().restart().play())
        : event.target.classList.contains("menu-about") &&
          document.querySelector(".menu-works").classList.contains("is-active")
        ? (this.menuAnimation.secondaryAnimation(
            ".menu-works",
            ".menu-contact",
            ".menu-about"
          ),
          this.menuAnimation.secondaryIconAnimation(
            ".menu-works-icon",
            ".menu-contact-icon",
            ".menu-about-icon"
          ),
          this.about.secondaryAnimation().restart().play())
        : null;
    } else if (!this.menuAnimation.animationIsActive) {

      // mobile animations
      // animate works
      event.target.classList.contains("menu-works") &&
      document.querySelector(".menu-about").classList.contains("is-active")
        ? (this.menuAnimation.primaryMobileAnimation(
            ".menu-about",
            ".menu-works",
            ".menu-contact"
          ),
          this.menuAnimation.primaryIconMobileAnimation(
            ".menu-about-icon",
            ".menu-works-icon",
            ".menu-contact-icon"
          ),
          this.works.primaryMobileAnimation().restart().play())
        : event.target.classList.contains("menu-works") &&
          document
            .querySelector(".menu-contact")
            .classList.contains("is-active")
        ? (this.menuAnimation.secondaryMobileAnimation(
            ".menu-contact",
            ".menu-about",
            ".menu-works"
          ),
          this.menuAnimation.secondaryIconMobileAnimation(
            ".menu-contact-icon",
            ".menu-about-icon",
            ".menu-works-icon"
          ),
          this.works.secondaryMobileAnimation().restart().play())
        : null;

      // animate contact
      event.target.classList.contains("menu-contact") &&
      document.querySelector(".menu-works").classList.contains("is-active")
        ? (this.menuAnimation.primaryMobileAnimation(
            ".menu-works",
            ".menu-contact",
            ".menu-about"
          ),
          this.menuAnimation.primaryIconMobileAnimation(
            ".menu-works-icon",
            ".menu-contact-icon",
            ".menu-about-icon"
          ),
          this.contact.primaryMobileAnimation().restart().play())
        : event.target.classList.contains("menu-contact") &&
          document.querySelector(".menu-about").classList.contains("is-active")
        ? (this.menuAnimation.secondaryMobileAnimation(
            ".menu-about",
            ".menu-works",
            ".menu-contact"
          ),
          this.menuAnimation.secondaryIconMobileAnimation(
            ".menu-about-icon",
            ".menu-works-icon",
            ".menu-contact-icon"
          ),
          this.contact.secondaryMobileAnimation().restart().play())
        : null;

      // animate about
      event.target.classList.contains("menu-about") &&
      document.querySelector(".menu-contact").classList.contains("is-active")
        ? (this.menuAnimation.primaryMobileAnimation(
            ".menu-contact",
            ".menu-about",
            ".menu-works"
          ),
          this.menuAnimation.primaryIconMobileAnimation(
            ".menu-contact-icon",
            ".menu-about-icon",
            ".menu-works-icon"
          ),
          this.about.primaryMobileAnimation().restart().play())
        : event.target.classList.contains("menu-about") &&
          document.querySelector(".menu-works").classList.contains("is-active")
        ? (this.menuAnimation.secondaryMobileAnimation(
            ".menu-works",
            ".menu-contact",
            ".menu-about"
          ),
          this.menuAnimation.secondaryIconMobileAnimation(
            ".menu-works-icon",
            ".menu-contact-icon",
            ".menu-about-icon"
          ),
          this.about.secondaryMobileAnimation().restart().play())
        : null;
    }
  }
}
