import gsap from "gsap";

export default class ContactAnimation {
  constructor() {}

  primaryMobileAnimation() {
    const animation = gsap
      .timeline({ paused: true })
      .to(".works-container", { opacity: 0, duration: 1. })
      .set(".works-img-mask", { yPercent: 0 })
      .set(".marquee-mask", { yPercent: 0 })
      .set(".works-text-container", { opacity: 0 })
      .set(".works-container", { display: "none" })
      .set(".contact-container", { display: "flex" })
      .to(".contact-container", { opacity: 1, duration: 1.8 })
    return animation;
  }

  secondaryMobileAnimation() {
    const animation = gsap
      .timeline({ paused: true })
      .to(".about-container", { opacity: 0 })
      .set(".text", { yPercent: 100 })
      .set(".about-img-mask", { yPercent: 0 })
      .set(".about-container", { display: "none" })
      .set(".contact-container", { display: "flex" })
      .to(".contact-container", { opacity: 1, duration: 0.8 });
    return animation;
  }

  primaryAnimation() {
    const animation = gsap
      .timeline({ paused: true, 
      // this is super bullshit, fix it when you have time! it does remove the active cursor from works page while animating out
      onUpdate: () => {
        if (document.querySelector('.works-container').matches(':hover')) {
          document
          .querySelector(".cursor--inner")
          .classList.remove("cursor--active")
        }

      }
       })
      .to(".works-text-container", { opacity: 0, duration: 0.3 }, 0)
      .to(".works-img-mask", { yPercent: 0, duration: 0.7, stagger: 0.3 }, 0)
      .set(".marquee-mask", { yPercent: 0 }, "<")
      .set(".works-container", { display: "none" })
      .set(".contact-container", { display: "flex" })
      .to(".contact-container", { opacity: 1, duration: 0.3 }, '<')
      .to('.side-color',{opacity: 0, duration: 0.3}, '-=1.0')
      // .from( ".button-color", 
      // { xPercent: 0, yPercent: 100, scale: 50, rotation: 50, duration: 1.5, ease: "power2.in" }, 1.
      //   )
      // .to('.button-color',{opacity: 0, duration: 1.5}, "-=0.7")
      .to('.side-color',{opacity: 1}, '-=0.3')
    return animation;
  }

  // CONTACT SECONDARY ANIMATION
  secondaryAnimation() {
    const animation = gsap
      .timeline({ paused: true, 
        // this is super bullshit, fix it when you have time! it does remove the active cursor from works page while animating out
        onUpdate: () => {

        } })
      // .fromTo(".side-color", { xPercent: 0 }, { xPercent: 100 })
      .to(".text", { yPercent: 100, duration: 0.6 }, 0)
      .to(".about-img-mask", { yPercent: 0, duration: 1., ease: 'power2.in' }, 0.1)
      .set('canvas', {display: 'none'})
      .set(".about-container", { display: "none" })
      .set(".contact-container", { display: "flex" })
      .to(".contact-container", { opacity: 1, duration: 2. })
      .to('.side-color',{opacity: 0, duration: 0.3}, '<')
      // .from( ".button-color", 
      // {  xPercent: -200, yPercent: -100, scale: 50, rotation: -50, duration: 1.5, ease: "power1.in" }, 1.
      //   )
      //   .to('.button-color',{opacity: 0, duration: 1.5}, "-=0.7")
      .to('.side-color',{opacity: 1}, '-=.3')
    return animation;
  }
}
