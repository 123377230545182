import gsap from 'gsap'
import Main from '../Main'
import Menu from '../../Main/Menu/Menu'

export default class WorksAnimation 
{
    constructor() 
    {
        this.main = new Main()
        this.menu = new Menu()
        this.activeAnimation = this.menu.animationIsActive
        this.sizes = this.main.sizes
        this.shrinking = this.shrink.bind(this)
        this.expanding = this.expand.bind(this)
        console.log(this.main)
    }

eventListeners() {
  // Add and remove event listeners for works sections
  if (this.sizes.desktop.matches) {
    document.querySelector('.narrative-wrapper').addEventListener("mouseleave", this.shrinking)
    document.querySelector('.destination-wrapper').addEventListener("mouseleave", this.shrinking)
    document.querySelector('.portrait-wrapper').addEventListener("mouseleave", this.shrinking)
    document.querySelector('.narrative-wrapper').addEventListener("mouseenter", this.expanding)
    document.querySelector('.destination-wrapper').addEventListener("mouseenter", this.expanding)
    document.querySelector('.portrait-wrapper').addEventListener("mouseenter", this.expanding)
  } else {
    document.querySelector('.narrative-wrapper').removeEventListener("mouseleave", this.shrinking)
    document.querySelector('.destination-wrapper').removeEventListener("mouseleave", this.shrinking)
    document.querySelector('.portrait-wrapper').removeEventListener("mouseleave", this.shrinking)
    document.querySelector('.narrative-wrapper').removeEventListener("mouseenter", this.expanding)
    document.querySelector('.destination-wrapper').removeEventListener("mouseenter", this.expanding)
    document.querySelector('.portrait-wrapper').removeEventListener("mouseenter", this.expanding)
  }
}

// works wrapper expand and shrink animations
expand(e) { 
  if(!this.menu.animationIsActive) {
    this.expandAnimation(e.target).play() 
  }

}
    
shrink(e) { 
    this.shrinkAnimation(e.target).play() 
}

expandAnimation(target) {
  const animation = gsap
  .timeline({ paused: true })
  .to(target, { width: '60%'})
  // Marquee animation
  .to(target.firstElementChild, { height: "8%" }, "<")
  .to(target.lastElementChild, { height: "8%" }, "<");
  return animation
} 

shrinkAnimation(target) {
  const animation = gsap
  .timeline({ paused: true })
  .to(target, { width: '33%'})
    // Marquee animation
  .to(target.firstElementChild, { height: "0%" }, "<")
  .to(target.lastElementChild, { height: "0%" }, "<");
  return animation
} 



// CONSTRUCTOR - MARQUEE ANIMATION
Marquee(targetText, xMovement, modMax, targetWidth) {
  // set initial position of each textbox element
  gsap.set(targetText, { x: (i, target) => {
      const pos = i * targetWidth;
      return pos;
    }
  });
  // move textboxes
  gsap.to(targetText, {
    duration: 20,
    ease: "none",
    x: xMovement, 
    modifiers: {
      //force x value to be between 0 and 775 using modulus
      x: gsap.utils.unitize((x) => parseFloat(x) % modMax), 
    },
    repeat: -1,
  });
}

primaryMobileAnimation() {
  const animation = gsap
  .timeline({ 
    paused: true
  })
    .set(".works-container", {opacity: 0})
    .to(".about-container", {opacity: 0})
    .set(".about-container", { display: 'none' })
    .set('.works-text-container', {display: 'flex', opacity: 1} )
    .set(".marquee-mask", { yPercent: -112 })
    .set(".works-container", { display: 'flex' })
    .to(".works-container", {opacity: 1, duration: 1}, 1.)
    return animation
} 

secondaryMobileAnimation() {
  const animation = gsap
  .timeline({ paused: true })
  .to(".contact-container", { opacity: 0 })
  .set(".contact-container", { display: 'none' })
  .set('.works-text-container', {opacity: 1 })
  .set(".works-container", { opacity: 0 })
  .set(".works-container", { display: 'flex' })
  .set(".marquee-mask", { yPercent: -112 }, "<")
  .to(".works-container", { opacity: 1 })
  return animation
}



primaryAnimation() {
  const animation = gsap
  .timeline({ 
    paused: true,
    onUpdate: () => {
      if (this.main.sizes.desktop.matches) {
        if (document.querySelector('.destination-wrapper').matches(':hover') ) {
          this.expandAnimation(document.querySelector('.destination-wrapper')).play() 
        } else if (document.querySelector('.narrative-wrapper').matches(':hover')) {
          this.expandAnimation(document.querySelector('.narrative-wrapper')).play()
        } else if (document.querySelector('.portrait-wrapper').matches(':hover')) {
          this.expandAnimation(document.querySelector('.portrait-wrapper')).play()
        } 
      }   
    },
    onComplete: () => {
      if (this.main.sizes.desktop.matches) {
      // check if cursor hovers over expanding elements
      if (document.querySelector('.destination-wrapper').matches(':hover') ) {
        this.expandAnimation(document.querySelector('.destination-wrapper')).play() 
      } else if (document.querySelector('.narrative-wrapper').matches(':hover')) {
        this.expandAnimation(document.querySelector('.narrative-wrapper')).play()
      } else if (document.querySelector('.portrait-wrapper').matches(':hover')) {
        this.expandAnimation(document.querySelector('.portrait-wrapper')).play()
      }      
    }   
    } 
  })

  // review this!
  .set(".works-container", {opacity: 1})
  .to(".about-img-mask", { yPercent: 0, duration: 1. })
  .to(".text", { yPercent: 100, duration: 0.7 }, '<0.4')
  .set('canvas', {display: 'none'})
  .set(".about-container", { display: 'none' })
  .set('.works-text-container', {opacity: 1})
  .set('.works-text-container', {display: 'flex'})
  .set(".works-container", { display: 'flex' })
  .to(".works-img-mask", { yPercent: -112, duration: 0.6, stagger: 0.2 })
  .to(".marquee-mask", { yPercent: -112, duration: 0.6, stagger: 0.1 }, "<")
  .from(".img", { scale: 1.4, duration: 1.5 }, "<")
  return animation
} 



secondaryAnimation() {
    const animation = gsap
    .timeline({ paused: true, 
      onUpdate: () => {
        if (this.main.sizes.desktop.matches) {
          if (document.querySelector('.destination-wrapper').matches(':hover') ) {
            this.expandAnimation(document.querySelector('.destination-wrapper')).play() 
          } else if (document.querySelector('.narrative-wrapper').matches(':hover')) {
            this.expandAnimation(document.querySelector('.narrative-wrapper')).play()
          } else if (document.querySelector('.portrait-wrapper').matches(':hover')) {
            this.expandAnimation(document.querySelector('.portrait-wrapper')).play()
          } 
        }   
      },
      onComplete: () => {
        if (this.main.sizes.desktop.matches) {
        // check if cursor hovers over expanding elements
        if (document.querySelector('.destination-wrapper').matches(':hover') ) {
          this.expandAnimation(document.querySelector('.destination-wrapper')).play() 
        } else if (document.querySelector('.narrative-wrapper').matches(':hover')) {
          this.expandAnimation(document.querySelector('.narrative-wrapper')).play()
        } else if (document.querySelector('.portrait-wrapper').matches(':hover')) {
          this.expandAnimation(document.querySelector('.portrait-wrapper')).play()
        }      
      }   
      } 
})
    // review this!
    .set('.works-text-container', {opacity: 0})
    .to(".contact-container", { opacity: 0, duration: 0.7 }, 0)
    .set(".contact-container", { display: 'none' })
    .set(".works-container", { display: 'flex' })
    .set(".works-container", {opacity: 1})
    .to('.works-text-container', {opacity: 1, duration: 0.3}, "<0.3")
    .to(".works-img-mask", {yPercent: -112, duration: 0.6, stagger: { amount: 0.2, from: "end" }}, '-=0.1')
    .to(".marquee-mask", { yPercent: -112, duration: 1, stagger: { amount: 0.15, from: "end" } }, "<")
    .from(".img", { scale: 1.4, duration: 1.5 }, "<")
    return animation
}

}