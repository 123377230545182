import gsap from "gsap";

export default class AboutAnimation {
  constructor() {
  }
  aboutIntroAnimation() {
    const animation = gsap
      .timeline({ paused: true })
      .set(".about-container", { display: "flex" })
      .set(".image-container", { yPercent: 0 })
      .set(".about-img-mask", { yPercent: 0 })

      .from(".text", { yPercent: 100, duration: 1.5, stagger: 0.1 }, 0)
      .to(".about-img-mask", { yPercent: -100, duration: 1.5 }, 0.3)
      .from(".portrait-mirko", { scale: 1.2, opacity: 0, duration: 1.5 }, "<")
    return animation;
  }

  primaryMobileAnimation() {
    const animation = gsap
      .timeline({ paused: true })
      .set(".about-container", { opacity: 0 })
      .to(".contact-container", { opacity: 0, duration: 1. }, 0)
      .set(".contact-container", { display: "none" })
      .set(".about-container", { display: "flex" })
      .set(".image-container", { yPercent: 0 })
      .set(".about-img-mask", { yPercent: 0 })
      .set(".about-img-mask", { yPercent: -100 })
      .set(".text", { yPercent: 0 })
      .to(".about-container", { opacity: 1, duration: 1. })
      .from(".portrait-mirko", { scale: 1.2, duration: 1.5 }, "<")
    return animation;
  }

  secondaryMobileAnimation() {
    const animation = gsap
      .timeline({ paused: true })
      .to(".works-container", { opacity: 0 })
      .set( ".works-img-mask", { yPercent: 0 })
      .set( ".marquee-mask", { yPercent: 0 })
      .set(".works-text-container", { opacity: 0 })
      .set(".works-container", { display: "none" })
      .set(".about-container", { display: "flex" })
      .set('canvas', {display: 'block'})
      .set(".image-container", { yPercent: 0 })
      .set( ".text", { yPercent: 0, duration: 0.6, stagger: 0.2 })
      .set(".about-img-mask", { yPercent: -100 })
      .to(".about-container", { opacity: 1 })
    return animation;
  }
  
  primaryAnimation() {
    const animation = gsap
      .timeline({ paused: true, 
             // this is super bullshit, fix it when you have time! it does remove the active cursor from works page while animating out
             onUpdate: () => {
              document
                .querySelector(".cursor--inner")
                .classList.remove("cursor--active")
            }
             
             })
      .set(".about-container", { opacity: 1 })
      .to(".contact-container", { opacity: 0, duration: 0.5 }, 0)
      .set(".contact-container", { display: "none" })
      .set(".about-container", { display: "flex" })
      .set('canvas', {display: 'block'})
      .set(".image-container", { yPercent: 0 })
      .set(".about-img-mask", { yPercent: 0 })
      .to(".about-img-mask", { yPercent: -100, duration: 1.5 })
      .from(".portrait-mirko", { scale: 1.2, duration: 1.5 }, "<")
      .fromTo(
        ".text",
        { yPercent: 100 },
        { yPercent: 0, duration: 0.6, stagger: 0.2 },
        "-=0.9"
      );
    return animation;
  }

  // ABOUT SECONDARY ANIMATION
  secondaryAnimation() {
    const animation = gsap
      .timeline({ paused: true, 
             // this is super bullshit, fix it when you have time! it does remove the active cursor from works page while animating out
             onUpdate: () => {
              if (document.querySelector('.works-container').matches(':hover')) {
                document
                .querySelector(".cursor--inner")
                .classList.remove("cursor--active")
              } 
            }
          })
      .to( ".works-img-mask", { yPercent: 0, duration: 0.9, stagger: { amount: 0.3, from: "end" } }, 0 )
      .to( ".marquee-mask", { yPercent: 0, duration: 0.9, stagger: { amount: 0.15, from: "end" } }, "<" )
      .to(".works-text-container", { opacity: 0, duration: 0.3 }, "<0.3")
      .set(".works-container", { display: "none" })
      .set(".about-container", { display: "flex" })
      .set('canvas', {display: 'block'})
      .set(".image-container", { yPercent: 0 })
      .fromTo( ".text", { yPercent: 100 }, { yPercent: 0, duration: 0.6, stagger: 0.2 } )
      .to(".about-img-mask", { yPercent: -100, duration: 0.7, ease: 'power2.in' }, "-=1.1");
    return animation;
  }
}
