import LocomotiveScroll from 'locomotive-scroll';

import Images from '../Images.js'


export default class CustomScroll {
    constructor() 
    {
        // Setup
        this.images = new Images()
        this.sizes = this.images.sizes
        this.init()
        this.hideBrowserScrollbar()
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: document.querySelector('.locomotive-scroll-container'),
            smooth: true,
            getDirection: true,
            gestureDirection: 'both',
            direction: 'horizontal',
            reloadOnContextChange: true,
            tablet: { 
                // define custom breakpoint to match css media queries
                breakpoint: 950,
                smooth: true,
                direction: 'vertical'
            },
            smartphone: {
                smooth: true,
                direction: 'vertical'
            }
        })

        this.scroll.on('scroll', (instance) => {
            document.documentElement.setAttribute('data-direction', instance.direction)
        });
    }

    hideBrowserScrollbar() {
        let scrollHide = document.createElement("style");
        scrollHide.innerHTML = `body::-webkit-scrollbar {display: none;}`;
        document.head.appendChild(scrollHide);
      }
    
    resize() {
        if(this.sizes.desktop.matches || this.sizes.tablet.matches) {
            if(this.scroll.direction === 'horizontal') {
                this.scroll.destroy()
                this.init()
            }
        } else if(this.sizes.mobile.matches) {
            if(this.scroll.direction === 'horizontal') {
                this.scroll.destroy()
                this.init()
            }
        } else {
            console.log('locomotive scroll issue')
        }
    }
}