import gsap from "gsap";


export default class ContactAnimation {
    constructor() {

      this.createBlur()
        this.menuItem = document.querySelectorAll(".menu-font");
        this.menuIcon = document.querySelector(".menu-icon-circle");
        this.menuIconItem = document.querySelectorAll(".menu-font-icon");
    }

    classRotationPrimary(active, primary, secondary) {
        document.querySelector(secondary).classList.remove("is-secondary");
        document.querySelector(secondary).classList.add("is-primary");
        document.querySelector(primary).classList.remove("is-primary");
        document.querySelector(primary).classList.add("is-active");
        document.querySelector(active).classList.remove("is-active");
        document.querySelector(active).classList.add("is-secondary");
    }

    classRotationSecondary(active, primary, secondary) {
        document.querySelector(secondary).classList.remove("is-secondary");
        document.querySelector(secondary).classList.add("is-active");
        document.querySelector(primary).classList.remove("is-primary");
        document.querySelector(primary).classList.add("is-secondary");
        document.querySelector(active).classList.remove("is-active");
        document.querySelector(active).classList.add("is-primary");
    }

    setFontOpacity1(target) {
        gsap.to(target, {opacity: 1., duration: 0.3})
      }
    
      setFontOpacity0(target) {
        gsap.to(target, {opacity: 0.5, duration: 0.3})
      }   

primaryAnimation(active, primary, secondary) {

    this.classRotationPrimary(active, primary, secondary)

    // Defaults
    const animation = gsap.timeline({
      defaults: {duration: 2},
      onStart: () => {
        this.animationIsActive = true;
      },
      onComplete: () => {
        this.animationIsActive = false;

        if(document.querySelector(active).matches(':hover')) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(active));
        } 
        if (document.querySelector(secondary).matches(':hover')) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(secondary));
        }
      }
    });

animation
    .to(active, { blur: 20, xPercent: 100, duration: 1, ease: "power2.in" })
    .set(active, { opacity: 0.5, left: 0, xPercent: -100, right: null, zIndex: 10 })
    .to(primary, { right: 0, left: null, ease: "power2.inOut" }, 0)
    .to(secondary, {left: this.menuItem[0].offsetWidth, right: null, ease: "power2.inOut"}, 0.3 )
    .to(active, { blur: 0, xPercent: 0, duration: 0.9, ease: "power2.out" }, 1.1);
    return animation;
  }

  secondaryAnimation(active, primary, secondary) {

            // toggle is-active class between active and primary menu element
            this.classRotationSecondary(active, primary, secondary)

    document.querySelector(active).classList.add('temp-cursor')
    // Defaults
    const animation = gsap.timeline({
      defaults: {duration: 2},
      onStart: () => {
        this.animationIsActive = true;
      },
      onComplete: () => {
        this.animationIsActive = false;
        if(document.querySelector(active).matches(':hover') ) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(active));
        } 
        if (document.querySelector(primary).matches(':hover')) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(primary));
        }       
      }      
    });

    // Animation
    animation
      .set(secondary, { xPercent: null })
      .to(secondary, { blur: 19, xPercent: -100, duration: 1 })
      .set(secondary, { right: 0, xPercent: 100, left: null, zIndex: 10 })
      .to( active, {opacity: 0.5, left: this.menuItem[0].offsetWidth, right: null, ease: "power2.inOut" }, 0 )
      .to( primary, { left: 0, xPercent: 0, right: null, ease: "power2.inOut" }, 0.3 )
      .to(secondary, { blur: 0, xPercent: 0, duration: 0.9, ease: "power2.out" }, 1.1);

    return animation;
  }

  primaryMobileAnimation(active, primary, secondary) {

    this.classRotationPrimary(active, primary, secondary)
    // Defaults
    const animation = gsap.timeline({
      onStart: () => {
        this.animationIsActive = true;
      },
      onComplete: () => {
        this.animationIsActive = false;
        if(document.querySelector(active).matches(':hover')) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(active));
        } 
        if (document.querySelector(secondary).matches(':hover')) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(secondary));
        }
        
      },
    });

    if(window.innerWidth>600) {
    // Animation
    animation
    .to(active, { yPercent: 100, duration: 1. })
    .set(active, { opacity: 1., top: 0, bottom: null, yPercent: -100, zIndex: 10 })
    .to(primary, { opacity: 0., bottom: 0, top: null, ease: "power2.inOut" , duration: 1.5}, 0)
    .to( secondary, { top: this.menuItem[0].offsetHeight, bottom: null, ease: "power2.inOut", duration: 0.7 }, 0.3 )
    .to(active, { yPercent: 0, duration: 0.5 }, 1.1);
    } else {
    // Animation
    animation
      .to(active, { yPercent: 100, duration: 1. })
      .set(active, { opacity: 1., top: 0, bottom: null, yPercent: -100, zIndex: 10 })
      .to(primary, { opacity: 0., bottom: 0, top: null, ease: "power2.inOut" , duration: 1.5}, 0)
      .to( secondary, { top: this.menuItem[0].offsetHeight, bottom: null, ease: "power2.inOut", duration: 0.7 }, 0.3 )
      .to(active, { yPercent: 0, duration: 0.5 }, 1.1);
    }

    return animation;
  }

  secondaryMobileAnimation(active, primary, secondary) {

    this.classRotationSecondary(active, primary, secondary)
    // Defaults
    const animation = gsap.timeline({ 
      onStart: () => {
        this.animationIsActive = true;
      },
      onComplete: () => {
        this.animationIsActive = false;
        if(document.querySelector(active).matches(':hover') ) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(active));
        } 
        if (document.querySelector(primary).matches(':hover')) {
          document.querySelector(".cursor--inner").classList.add("cursor--active");
          this.setFontOpacity1(document.querySelector(primary));
        }  
      },
    });

    if(window.innerWidth>600) {
    // Animation
    animation
    .to(secondary, { top: 0, yPercent: -100, bottom: null, duration: 1 })
    .set(secondary, { opacity: 0., top: null, bottom: 0, yPercent: 100, zIndex: 10 })
    .to( active, { opacity: 0.5, top: this.menuItem[0].offsetHeight, bottom: null, ease: "power2.inOut", duration: 1.5 }, 0 )
    .to(primary, { top: 0, bottom: null, ease: "power2.inOut", duration: 0.7 }, 0.3)
    .to(secondary, { yPercent: 0, duration: 0.5 }, 1.1);
    } else {
    // Animation
    animation
      .to(secondary, { top: 0, yPercent: -100, bottom: null, duration: 1 })
      .set(secondary, { opacity: 0., top: null, bottom: 0, yPercent: 100, zIndex: 10 })
      .to( active, { opacity: 1., top: this.menuItem[0].offsetHeight, bottom: null, ease: "power2.inOut", duration: 1.5 }, 0 )
      .to(primary, { top: 0, bottom: null, ease: "power2.inOut", duration: 0.7 }, 0.3)
      .to(secondary, { yPercent: 0, duration: 0.5 }, 1.1);
    }

    return animation;
  }

  primaryIconAnimation(active, primary, secondary) {
    const animation = gsap.timeline({ defaults: { duration: 2 } });

    // Animation
    animation
    .to(active, { xPercent: 100, opacity: 0, duration: 1, ease: "power2.in" })
    .set(active, { left: 0, xPercent: -100, right: null, zIndex: 10 })
    .to(primary, { right: 0, left: null, ease: "power2.inOut" }, 0)
    .to(secondary, {left: this.menuIconItem[0].offsetWidth, right: null, ease: "power2.inOut"}, 0.3 )
    .to(active, { xPercent: 0, opacity: 1, duration: 0.9, ease: "power2.out" }, 1.1);

    // toggle is-active class between active and primary menu element
    document.querySelector(primary).classList.toggle("is-active");
    document.querySelector(active).classList.toggle("is-active");

    return animation;
  }

  secondaryIconAnimation(active, primary, secondary) {
    // Defaults
    const animation = gsap.timeline({ defaults: { duration: 2 } });

    // Animation
    animation
    .set(secondary, { xPercent: null })
    .to(secondary, { xPercent: -100, opacity: 0, duration: 1 })
    .set(secondary, { right: 0, xPercent: 100, left: null, zIndex: 10 })
    .to( active, {left: this.menuIconItem[0].offsetWidth, right: null, ease: "power2.inOut" }, 0 )
    .to( primary, { left: 0, xPercent: 0, right: null, ease: "power2.inOut" }, 0.3 )
    .to(secondary, { xPercent: 0, duration: 0.9, opacity: 1, ease: "power2.out" }, 1.1);

    // toggle is-active class between active and primary menu element
    document.querySelector(primary).classList.toggle("is-active");
    document.querySelector(active).classList.toggle("is-active");

    return animation;
  }

  primaryIconMobileAnimation(active, primary, secondary) {
    // Defaults
    const animation = gsap.timeline({
      onStart: () => {
        this.animationIsActive = true;
      },
      onComplete: () => {
        this.animationIsActive = false;
      },
    });

    // Animation
    animation
      .to(active, { yPercent: 100, opacity: 0, duration: 1. })
      .set(active, { top: 0, bottom: null, yPercent: -100, zIndex: 10 })
      .to(primary, { bottom: 0, top: null, ease: "power2.inOut" , duration: 1.}, 0)
      .to( secondary, { top: this.menuIconItem[0].offsetHeight, bottom: null, ease: "power2.inOut", duration: 0.7 }, 0.3 )
      .to(active, { opacity: 1, yPercent: 0 });

    // toggle is-active class between active and primary menu element
    
    return animation;
  }

  secondaryIconMobileAnimation(active, primary, secondary) {
    // Defaults
    const animation = gsap.timeline({
      onStart: () => {
        this.animationIsActive = true;
      },
      onComplete: () => {
        this.animationIsActive = false;
      },
    });

    // Animation
    animation
    .to(secondary, { opacity: 0, top: 0, yPercent: -100, bottom: null, duration: 1 })
    .set(secondary, { top: null, bottom: 0, yPercent: 100, zIndex: 10 })
    .to( active, { opacity: 1, top: this.menuIconItem[0].offsetHeight, bottom: null, ease: "power2.inOut", duration: 1 }, 0 )
    .to(primary, { top: 0, bottom: null, ease: "power2.inOut", duration: 0.7 }, 0.3)
    .to(secondary, { opacity: 1, yPercent: 0, duration: 1 });
    return animation;
  }

  createBlur() {
    //this is just an example plugin that allows us to animate a "blur" property like gsap.to(target, {blur:10}) and it'll feed that value to this plugin which will do all the necessary calculations to add/update a blur() value in the CSS "filter" property (in browsers that support it). We wrap it in an iife just so that we can declare some local variables in a private scope at the top.
const blurProperty = gsap.utils.checkPrefix("filter"),
      blurExp = /blur\((.+)?px\)/,
      getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

gsap.registerPlugin({
  name: "blur",
  get(target) {
    return +(getBlurMatch(target)[1]) || 0;
  },
  init(target, endValue) {
    let data = this,
        filter = gsap.getProperty(target, blurProperty),
        endBlur = "blur(" + endValue + "px)",
        match = getBlurMatch(target)[0],
        index;
    if (filter === "none") {
      filter = "";
    }
    if (match) {
      index = filter.indexOf(match);
      endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
    } else {
      endValue = filter + endBlur;
      filter += filter ? " blur(0px)" : "blur(0px)";
    }
    data.target = target; 
    data.interp = gsap.utils.interpolate(filter, endValue); 
  },
  render(progress, data) {
    data.target.style[blurProperty] = data.interp(progress);
  }
});


//from now on, we can animate "blur" as a number! (Well, in browsers that support filter)

}

}