import "./css/specimen_files/grid_12-825-55-15.css"
import "./css/specimen_files/specimen_stylesheet.css"
import "./css/specimen_files/stylesheet.css"
import "./css/main.css"
import CustomCursor from "./Main/Utils/CustomCursor"
import Main from "./Main/Main.js";
import Images from "./Main/Images.js";

import "@lottiefiles/lottie-player"
import lottieAnimation from '../static/lottie/lottie.json'

let instance = null;
class init {
  constructor() {
    // Singleton
    if (instance) {
      return instance;
    }
    instance = this;

    //Setup
    this.menuIcon = document.querySelector(".menu-icon-circle");
    this.menuIcon.addEventListener("click", this.jsAnchorTagMenu);
    this.preloader = document.querySelector('.preloader')
    this.player = document.querySelector('lottie-player')


    //Methods
    this.lottieAnimation()
  }

  //Methods
  jsAnchorTagMenu() {
    window.location.href = "index.html";
  }

  lottieAnimation() {
    // const player = document.querySelector('lottie-player');
    this.player.load(lottieAnimation);
  }

  destroyPreloader() { 
    this.preloader.parentElement.removeChild(this.preloader);
  }

}

window.addEventListener("DOMContentLoaded", () => {
  let initialize = new init();
  console.log('zero')
})

// let initialize = new init();

window.addEventListener("load", () => {

  let initialize = new init();
  console.log('first')
  
  if (document.querySelector(".main")) {
    const main = new Main();
    initialize.destroyPreloader()
  } 
  
  else if (
    document.querySelector(".destination-bg") ||
    document.querySelector(".shader-bg") ||
    document.querySelector(".narrative-bg")
  ) {
    const main = new Images();
    initialize.destroyPreloader()
  }
})
