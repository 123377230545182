import gsap from 'gsap'
import EventEmitter from './EventEmitter.js'
import Main from '../Main.js'


export default class Sizes extends EventEmitter
{
    constructor()
    {
        super()

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

        // Setup
        // MAIN CLASS
        if(document.querySelector('body').classList.contains('.main')) {
          this.main = new Main()
        }
      
        this.mobile = window.matchMedia("(max-width: 950px)");
        this.tablet = window.matchMedia("(min-width: 951px) and (max-width: 1600px)");
        this.desktop = window.matchMedia("(min-width: 1601px)");
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)

        // MAIN VARIABLES
          this.menuItem = document.querySelectorAll('.menu-font')
          this.menuIconItem = document.querySelectorAll(".menu-font-icon");
          this.worksWrapper = document.querySelectorAll('.works-wrapper')

        // Resize event
        window.addEventListener('resize', () =>
        {
            this.width = window.innerWidth
            this.height = window.innerHeight
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)
            this.trigger('resize')
        })
    }

    handleResize() {
    if (this.desktop.matches) {
        this.worksWrapper[0].setAttribute("style", "width: 33%");
        this.worksWrapper[1].setAttribute("style", "width: 33%");
        this.worksWrapper[2].setAttribute("style", "width: 33%");
        gsap.set('.works-text-container', {height: '0%'})

        gsap.set(".menu-font", { bottom: 0, top: null, left: null, right: null, xPercent: null });
        if (document.querySelector('.menu-about').classList.contains("is-active")) {
          gsap.set(".menu-about", { right: 0, top: null, left: null, bottom: 0 });
          gsap.set(".menu-works", { left: this.menuItem[0].offsetWidth, top: null, right: null, bottom: 0 });
          gsap.set(".menu-contact", { left: 0, top: null, right: null, bottom: 0 });

          gsap.set(".menu-about-icon", { right: 0, top: null, left: null, bottom: 0 });
          gsap.set(".menu-works-icon", { left: this.menuIconItem[0].offsetWidth, top: null, right: null, bottom: 0 });
          gsap.set(".menu-contact-icon", { left: 0, top: null, right: null, bottom: 0 });
        } else if (document.querySelector('.menu-works').classList.contains("is-active")) {
          gsap.set(".menu-works", { right: 0, left: null, top: null, bottom: 0 });
          gsap.set(".menu-contact", { left: this.menuItem[0].offsetWidth, top: null, right: null, bottom: 0 });
          gsap.set(".menu-about", { left: 0, top: null, right: null, bottom: 0 });

          gsap.set(".menu-works-icon", { right: 0, left: null, top: null, bottom: 0 });
          gsap.set(".menu-contact-icon", { left: this.menuIconItem[0].offsetWidth, top: null, right: null, bottom: 0 });
          gsap.set(".menu-about-icon", { left: 0, top: null, right: null, bottom: 0 });
        } else {
          gsap.set(".menu-contact", { right: 0, left: null, top: null, bottom: 0 });
          gsap.set(".menu-about", { left: this.menuItem[0].offsetWidth, top: null, right: null, bottom: 0 });
          gsap.set(".menu-works", { left: 0, top: null, right: null, bottom: 0 });

          gsap.set(".menu-contact-icon", { right: 0, left: null, top: null, bottom: 0 });
          gsap.set(".menu-about-icon", { left: this.menuIconItem[0].offsetWidth, top: null, right: null, bottom: 0 });
          gsap.set(".menu-works-icon", { left: 0, top: null, right: null, bottom: 0 });
        }


      }
  
      if (this.tablet.matches) {
        this.worksWrapper[0].setAttribute("style", "width: 100%");
        this.worksWrapper[1].setAttribute("style", "width: 100%");
        this.worksWrapper[2].setAttribute("style", "width: 100%");
        gsap.set('.works-text-container', {height: '8%'})

        gsap.set(".menu-font", { top: 0, bottom: null, left: null, right: null, xPercent: null });
        if (document.querySelector('.menu-about').classList.contains("is-active")) {
          gsap.set(".menu-about", { top: 0, right: 0, left: null, bottom: null });
          gsap.set(".menu-works", { top: 0, left: this.menuItem[0].offsetWidth, yPercent: null, right: null, bottom: null });
          gsap.set(".menu-contact", { top: 0, left: 0, right: null, bottom: null });

          gsap.set(".menu-about-icon", { top: 0, right: 0, left: null, bottom: null });
          gsap.set(".menu-works-icon", { top: 0, left: this.menuIconItem[0].offsetWidth, yPercent: null, right: null, bottom: null });
          gsap.set(".menu-contact-icon", { top: 0, left: 0, right: null, bottom: null });
        } else if (document.querySelector('.menu-works').classList.contains("is-active")) {
          gsap.set(".menu-works", { top: 0, right: 0, left: null, bottom: null });
          gsap.set(".menu-contact", { top: 0, left: this.menuItem[0].offsetWidth, right: null, bottom: null });
          gsap.set(".menu-about", { top: 0, left: 0, right: null, bottom: null });

          gsap.set(".menu-works-icon", { top: 0, right: 0, left: null, bottom: null });
          gsap.set(".menu-contact-icon", { top: 0, left: this.menuIconItem[0].offsetWidth, right: null, bottom: null });
          gsap.set(".menu-about-icon", { top: 0, left: 0, right: null, bottom: null });
        } else {
          gsap.set(".menu-contact", { top: 0, right: 0, left: null, bottom: null });
          gsap.set(".menu-about", { top: 0, left: this.menuItem[0].offsetWidth, right: null, bottom: null });
          gsap.set(".menu-works", { top: 0, left: 0, right: null, bottom: null });

          gsap.set(".menu-contact-icon", { top: 0, right: 0, left: null, bottom: null });
          gsap.set(".menu-about-icon", { top: 0, left: this.menuIconItem[0].offsetWidth, right: null, bottom: null });
          gsap.set(".menu-works-icon", { top: 0, left: 0, right: null, bottom: null });
        }
      }
  
      if (this.mobile.matches) {
        this.worksWrapper[0].setAttribute("style", "width: 100%");
        this.worksWrapper[1].setAttribute("style", "width: 100%");
        this.worksWrapper[2].setAttribute("style", "width: 100%");

        gsap.set(".menu-font", { top: 0, bottom: null, left: null, right: null, xPercent: null  });
        if (document.querySelector('.menu-about').classList.contains("is-active")) {
          gsap.set(".menu-about", { left: 0, bottom: 0, top: null, right: null });
          gsap.set(".menu-works", { left: 0, top: this.menuItem[0].offsetHeight, bottom: null, right: null });
          gsap.set(".menu-contact", { left: 0, right: null });

          gsap.set(".menu-about-icon", { left: 0, bottom: 0, top: null, right: null });
          gsap.set(".menu-works-icon", { left: 0, top: this.menuIconItem[0].offsetHeight, bottom: null, right: null });
          gsap.set(".menu-contact-icon", { left: 0, right: null });
        } else if (document.querySelector('.menu-works').classList.contains("is-active")) {
          gsap.set(".menu-works", { left: 0, bottom: 0, top: null, right: null });
          gsap.set(".menu-contact", { left: 0, top: this.menuItem[0].offsetHeight, bottom: null, right: null });
          gsap.set(".menu-about", { left: 0, right: null });

          gsap.set(".menu-works-icon", { left: 0, bottom: 0, top: null, right: null });
          gsap.set(".menu-contact-icon", { left: 0, top: this.menuIconItem[0].offsetHeight, bottom: null, right: null });
          gsap.set(".menu-about-icon", { left: 0, right: null });
        } else {
          gsap.set(".menu-contact", { left: 0, bottom: 0, top: null, right: null });
          gsap.set(".menu-about", { left: 0, top: this.menuItem[0].offsetHeight, bottom: null, right: null });
          gsap.set(".menu-works", { left: 0, right: null });

          gsap.set(".menu-contact-icon", { left: 0, bottom: 0, top: null, right: null });
          gsap.set(".menu-about-icon", { left: 0, top: this.menuIconItem[0].offsetHeight, bottom: null, right: null });
          gsap.set(".menu-works-icon", { left: 0, right: null });
        }
      }
    }
}